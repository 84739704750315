import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { FormBuilder, getDateKeys } from '../components/formBuilder';
import { baseUrl } from '../config';
import { Button, Form } from 'antd';
import moment from 'moment';


const ActionItems = styled.div`
    float: right;
`;

function FormFiller(){
    const { name } = useParams();
    const [id, setId] = useState(null);
    let [searchParams, setSearchParams] = useSearchParams();
    const [formMeta, setFormMeta] = useState({name});
    const [formRef] = Form.useForm();

    useEffect(() => {
        getForm().then((ff) => {
            const id = searchParams.get('id');
            if (id) {
                setId(id);
                getFormData(id).then(data => {
                    getDateKeys(ff.fields).forEach(key => {
                        if (data[key]) {
                            data[key] = moment(data[key]);
                        }
                    });
                    formRef.setFieldsValue(data);
                });
            }
        });
    }, []);

    async function getForm(){
        try {
            const response = await fetch(`${baseUrl}/form/${name}`);
            const data = await response.json();
            console.log(data.data);
            const ff = data.data || {};
            setFormMeta(ff);
            return ff;
        } catch (error) {
            console.error(error);
            alert('Error getting form metadata');
        }
    }

    async function getFormData(id){
        try {
            const response = await fetch(`${baseUrl}/form/${name}/${id}`);
            const data = await response.json();
            console.log(data.data);
            const d = data.data || {};
            return d;
        } catch (error) {
            console.error(error);
            alert('Error getting form data');
            return {};
        }
    }

    async function saveData(values){
        const response = await fetch(`${baseUrl}/form/${name}/submit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        });
        if (response.status !== 200) {
            throw new Error('Error submitting form');
        }
        const data = await response.json();
        setId(data?.data[0]?._id);
        setSearchParams({id: data?.data[0]?._id});
        return data.data;
    }

    async function updateData(id, values){
        const response = await fetch(`${baseUrl}/form/${name}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        });
        if (response.status !== 200) {
            throw new Error('Error updating form');
        }
        const data = await response.json();
        return data.data;
    }

    async function submitForm(){
        try {
            const values = await formRef.validateFields();
            console.log('Form values', values);
            let data;
            if (id) {
                data = await updateData(id, values);
            } else {
                data = await saveData(values);
            }
            console.log('Form submitted', data);
            alert('Form submitted successfully');
        } catch (error) {
            console.error(error);
            if (error.errorFields) {
                alert(error.errorFields.map(f => f.errors).join('\n'));
            } else {
                alert('Error submitting form');
            }
        }
    }

    return (
        <div style={{padding: 30, display: 'flex', flexDirection: 'column', height: '100%'}}>
            <h1>
                {(formMeta && formMeta.title) || name}
                <ActionItems>
                    <Button type="primary" onClick={submitForm}>Submit</Button>
                </ActionItems>
            </h1>
            <div style={{flex: '1 1 auto', overflowY: 'auto', overflowX: 'hidden'}}>
                <FormBuilder data={formMeta || {name}} form={formRef} />
            </div>
        </div>
    );
}

export default FormFiller;