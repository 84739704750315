import { ConfigProvider } from "antd";
import { createHashRouter, RouterProvider } from "react-router-dom";
import FormMaker from "./pages/formMaker";
import FormFiller from "./pages/formFiller";
import './App.css';
import Welcome from "./pages/welcome";

const router = [
  {
    path: '/maker',
    Component: FormMaker
  },
  {
    path: 'fill/:name',
    Component: FormFiller,
  },
  {
    path: 'enrollent/:id',
    Component: Welcome
  },
  {
    index: true,
    Component: Welcome
  }
];

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#0057b1'
        },
      }}
    >
        <RouterProvider router={createHashRouter(router)} />
    </ConfigProvider>
  );
}

export default App;