import { Col, Form, Row } from 'antd';
import { createElement, useState } from 'react';
import { get } from 'lodash';

import { Space, AutoComplete, Cascader, Checkbox, ColorPicker, DatePicker, Table, Input, InputNumber, Mentions, Rate, Radio, Select, Slider, Switch, TimePicker, Transfer, TreeSelect, Upload, QRCode, Divider } from 'antd';
const { Password, OTP, Search, TextArea, Group: InputGroup } = Input;
const { Button: RadioButton, Group: RadioGroup } = Radio;
const { MonthPicker, RangePicker } = DatePicker;

function getComponent(field, form, values) {
  try {
    if (field && typeof field === 'string') {
      return field;
    }
    if (!field || !field.type) {
      return null;
    }
    const { type, children, itemProps = {}, showIf } = field;

    if (showIf) {
      const show = eval(showIf);
      if (!show) return null
    }

    const props = Object.keys(itemProps).reduce((p, key) => {
      const prop = itemProps[key];
      if (prop.type && prop.type === 'eval') {
        return { ...p, [key]: eval(prop.value) };
      } else {
        return { ...p, [key]: prop };
      }
    }, {});
    if (!Components[type]) {
      return null;
    }
    return createElement(Components[type], props, children && (children || []).map(child => getComponent(child, form)));
  } catch (e) {
    console.error(e);
  }
  return null;
}

const Components = {
  'AutoComplete': AutoComplete,
  'Cascader': Cascader,
  'Checkbox': Checkbox,
  'ColorPicker': ColorPicker,
  'DatePicker': DatePicker,
  'MonthPicker': MonthPicker,
  'RangePicker': RangePicker,
  'Input': Input,
  'TextArea': TextArea,
  'Search': Search,
  'InputGroup': InputGroup,
  'Password': Password,
  'OTP': OTP,
  'InputNumber': InputNumber,
  'Mentions': Mentions,
  'Rate': Rate,
  'Radio': Radio,
  'RadioButton': RadioButton,
  'RadioGroup': RadioGroup,
  'Select': Select,
  'Slider': Slider,
  'Switch': Switch,
  'TimePicker': TimePicker,
  'Transfer': Transfer,
  'TreeSelect': TreeSelect,
  'Upload': Upload,
  'QRCode': QRCode,
  'Divider': Divider,
  'Space': Space,
  'Table': Table
};

const datePickers = ['DatePicker', 'TimePicker'];
export function getDateKeys(fields) {
  if (!fields || !fields.length) return [];
  fields = fields.filter(f => datePickers.includes(f.type));
  return fields.map(f => get(f, 'fieldProps.name'));
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function FormBuilder({ data, form }) {
  const { name = '', fields = [], formProps = {} } = data;
  const [values, setValues] = useState({});
  if (!name || !fields || !fields.length) return null;

  function onFormChange(name, { _, forms }) {
    setValues(forms[name].getFieldsValue());
  }

  let fieldElems = fields.map((field, index) => {
    if (!field) return null;
    if (field.showIf) {
      try {
        const show = eval(field.showIf);
        if (!show) return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
    return createElement(
      Col,
      { span: 24, key: index },
      field.fieldProps ?
        createElement(Form.Item, { ...field.fieldProps, key: index }, getComponent(field, form, values)) :
        getComponent(field, form, values)
    )
  });

  const f = createElement(
    Form,
    { layout: "vertical", name, form, scrollToFirstError: true, ...evaluatorProps(formProps) },
    createElement(
      Row,
      { gutter: 24 },
      fieldElems));
  return <Form.Provider onFormChange={onFormChange}>
    {f}
  </Form.Provider>;
}

export function evaluatorProps(obj) {
  try {
    return Object.keys(obj).reduce((p, key) => {
      const prop = obj[key];
      if (prop.type && prop.type === 'eval') {
        return { ...p, [key]: eval(prop.value) };
      } else {
        return { ...p, [key]: typeof (prop) === 'object' ? evaluatorProps(prop) : prop };
      }
    }, {});
  } catch (error) {
    console.error(error);
  }
}