import React, { createElement } from 'react';
import MonacoEditor from 'react-monaco-editor';
import { FormBuilder } from '../components/formBuilder';
import { Button, Divider, Form, Layout, Select, Typography, Radio } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { baseUrl } from '../config';


const { Content, Header } = Layout;
const { Text } = Typography;



const MyContainer = styled.div`
  height: 100%;
  width: 50%;
  padding: 30px;
  overflow: scroll; 
`;

const MyWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ActionItems = styled.div`
  float: right;
`;


function FormMaker() {
  const [errorMessages, setErrorMessages] = React.useState('');
  const [view, setView] = React.useState('{}');

  const [form, setForm] = React.useState(null);
  const [dataViewerTable, setDataViewerTable] = React.useState(null);
  const [dataView, setDataView] = React.useState(null);

  const [cWidth, setCWidth] = React.useState(localStorage.getItem('cWidth') || 400);

  const [enableSave, setEnableSave] = React.useState(false);

  const [formList, setFormList] = React.useState([]);

  const [formRef] = Form.useForm();

  React.useEffect(() => {
    getAllForms();
    setDataView(form);
  }, [form]);

  function onChange(value) {
    try {
      const d = JSON.parse(value);
      let v = JSON.stringify(d, null, 2);
      setView(v);
      localStorage.setItem('data', v);
      setForm(createElement(FormBuilder, { data: d, form: formRef }));
      setEnableSave(true);
      setErrorMessages('');
    } catch (e) {
      setEnableSave(false);
      setErrorMessages(e.message);
    }
  }

  async function getAllForms() {
    try {
      const response = await axios.get(`${baseUrl}/form/all`);
      console.log('All forms', response.data);
      if (response.status !== 200) {
        return;
      }
      setFormList(response.data.data || []);
    } catch (e) {
      console.error(e);
    }
  }

  async function onSave() {
    console.log('Save');
    if (!enableSave || errorMessages) {
      alert('Please fix the errors before saving');
      return;
    }
    try {
      const formContent = JSON.parse(view);
      console.log('Saving form', formContent);
      await axios.post(`${baseUrl}/form/save`, formContent, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      alert('Form saved successfully');
    } catch (e) {
      alert('Error saving form');
      console.error(e);
    }
  }

  const loadData = (dataType) => {
    if(dataType === 'form') {
      setDataView(form);
    } else {
      setDataView(dataViewerTable);
      const a = localStorage.getItem('data');
      console.log(a.dataViewer);
      setForm(createElement(FormBuilder, { data: view.dataViewer, form: formRef }));
    }
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Header style={{ color: 'white' }}>
        <h2 style={{padding: 0, margin: 0}}>
          Form Builder
          <ActionItems>
            <Select style={{ width: 200, margin: '10px' }} placeholder="Select Form" onSelect={onChange}> 
              <Select.Option key="" value="{}" >Add New</Select.Option>
              {formList.map((form) => (
                <Select.Option key={form.name} value={JSON.stringify(form)} >{form.title}</Select.Option>
              ))}
            </Select>
            <Button type="primary" onClick={onSave} disabled={!enableSave}>Save Form</Button>
          </ActionItems>
        </h2>
      </Header>
      <Layout>
        <Content>
          <MyWrapper>
            <MyContainer style={{flexGrow: 1}}>
            <Radio.Group defaultValue="form" buttonStyle="solid">
                <Radio.Button value="form" onClick={() => loadData('form')}>Form</Radio.Button>
                <Radio.Button value="data-table" onClick={() => loadData('dataViewerTable')}>Data Table</Radio.Button>
              </Radio.Group>
              {dataView}
            </MyContainer>
            <Divider
              type="vertical"
              style={{ height: '100%', margin: 0, cursor: 'ew-resize', padding: 5 }}
              draggable
              onDrag={(e) => {
                const newWidth = window.innerWidth - e.clientX;
                setCWidth(newWidth);
                localStorage.setItem('cWidth', newWidth);
              }}
            />
            <div style={{width: cWidth, flexShrink: 0, height: '100%'}}>
              <Text type="danger">{errorMessages}</Text>
              <MonacoEditor
                width={cWidth}
                theme="vs-dark"
                value={view}
                options={{
                  selectOnLineNumbers: true,
                  language: 'json',
                  dragAndDrop: true,
                  lightbulb: {enabled: true},
                  tabCompletion: 'on',
                }}
                onChange={onChange}
                editorDidMount={(editor, monaco) => {
                  editor.focus();
                }}
              />
            </div>
          </MyWrapper>
        </Content>
      </Layout>
      {/* <Footer>footer</Footer> */}
    </Layout>
  );
}



export default FormMaker;
