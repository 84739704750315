import React, { useEffect } from 'react';
import styled from 'styled-components';
import { baseUrl } from '../config';
import { Table, Row, Col, Typography, Layout, Menu, Space } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { evaluatorProps } from '../components/formBuilder';

const { Title } = Typography;
const { Sider, Content } = Layout;

const MyLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 2em;
    color: white;
    margin-bottom: 1em;
}`;

const Welcome = () => {
    const [formList, setFormList] = React.useState([]);

    const [selectedForm, setSelectedForm] = React.useState(null);

    const [data, setData] = React.useState([]);

    useEffect(() => {
        getAllForms();
    }, []);

    async function getAllForms() {
        try {
            const response = await fetch(`${baseUrl}/form/all`);
            const data = await response.json();
            setFormList(data.data || []);
        } catch (error) {
            console.error(error);
        }
    }

    async function getFormDataByFormName(formName) {
        try {
            const response = await fetch(`${baseUrl}/form/${formName}/all?page=0&limit=10`);
            const data = await response.json();
            setData(data.data || []);
        } catch (error) {
            console.error(error);
        }
    }

    const items = [
        {
            key: 'forms',
            label: 'My Forms',
            icon: <MailOutlined />,
            children: formList.map(form => {
                return {
                    key: form.name,
                    label: form.title,
                    onClick: () => {
                        setSelectedForm(form);
                        getFormDataByFormName(form.name);
                    }
                }
            })
        },
        {
            key: 'new',
            label: 'Form Builder',
            icon: <MailOutlined />,
            onClick: () => {
                window.location.href = '#/maker';
            }
        }
    ];

    function onFormSelect({ item, key, keyPath, selectedKeys }) {
        console.log('Selected Form', key, item, keyPath, selectedKeys);
    }

    function evaluateColumns(columns) {
        const moment = require('moment');
        return columns.map(col => {
            if (col.render){
                col.render = eval(col.render);
            }
            return col;
        });
    }

    return (
        <Layout style={{width: '100%', height: '100%'}} hasSider>
            <Sider width={300} breakpoint='md'>
                <MyLogo>FormBuilder</MyLogo>
                <Menu mode="inline" items={items} onSelect={onFormSelect} />
            </Sider>
            <Layout>
                <Content style={{ margin: 16, overflow: 'initial', backgroundColor: '#fff', borderRadius: 10, padding: 20 }}>
                    <Title level={2}>{selectedForm ? selectedForm.title : 'Welcome'}</Title>
                    <Space />
                    {
                        selectedForm && selectedForm.table &&
                        <Table {...evaluatorProps(selectedForm.table.props)} dataSource={data} columns={evaluateColumns(selectedForm.table.columns || [])} />
                    }
                </Content>
            </Layout>
        </Layout>
        
    );
};

export default Welcome;